import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/index/index.vue')
  },
  {
    path: '/loginpage',
    name: 'loginpage',
    component: () => import('@/views/login/index.vue')
  },
  {
    path: '/registerpage',
    name: 'registerpage',
    component: () => import('@/views/register/index.vue')
  },
  {
    path: '/usermain',
    name: 'usermain',
    component: () => import('@/views/usermain/index.vue')
  },
  {
    path: '/404',
    component: () => import('@/views/404.vue'),
    hidden: true
  },
  { path: '*', redirect: '/404', hidden: true }
]

const router = new VueRouter({
  routes
})

// 路由守卫
const whiteList = ['/home', '/loginpage', '/registerpage', '/404']

router.beforeEach((to, from, next) => {
  // const token = store.state.user.token
  const token = store.state.user.token.token
  if (token) {
    next()
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      next('/login')
    }
  }
})

export default router
