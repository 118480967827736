import axios from 'axios'
import store from '@/store'

const service = axios.create({
  baseURL: 'http://124.220.93.162:3400',
  timeout: 10000
})

// 添加请求拦截器
service.interceptors.request.use(function (config) {
  const token = store.state.user.token.token
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  // 在发送请求之前做些什么
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use(function (response) {
  // 2xx 范围内的状态码都会触发该函数。
  // 对响应数据做点什么
  return response
}, function (error) {
  // 超出 2xx 范围的状态码都会触发该函数。
  // 对响应错误做点什么
  return Promise.reject(error)
})

export default service
