// 两根线
// 同步：组件 commit -> mutations -> state
// 异步： 组件 dispatch -> actions -> commit -> mutations -> state
import { login } from '@/api/login'
import { Message } from 'element-ui'

// import { setToken, getToken } from '@/utils/stotage' 改
export default {
  // 开启命名空间
  namespaced: true,
  // 放数据的地方
  state: () => ({
    // 先去拿token,如果没有就空
    // token: getToken() || '' 改
    token: ''
  }),
  // 相当于组件中计算属性
  getters: {},
  // 这个是唯一修改state中数据地方
  mutations: {
    // setCount(state) {}
    updateToken (state, payload) {
      state.token = payload
      // 存token
      // setToken(payload) 改
    }
  },
  // 写异步的ajax的地方
  actions: {
    // store.commit()
    // getData(store){}
    async login (context, payload) {
      const res = await login(payload)
      console.log('结果', res)
      if (res.data.flag === true) {
        Message({
          showClose: true,
          message: res.data.message,
          type: 'success'
        })
      } else {
        Message({
          showClose: true,
          message: res.data.message,
          type: 'warning'
        })
      }

      //   context.commit('updateToken', res.data.token)
      context.commit('updateToken', res.data)
    }
  }
}
