import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './element-variables.scss'
import * as echarts from 'echarts'

// 自定义指令防抖
import throttle from '@/directive/fangdou'
// 注册指令
Vue.directive('throttle', throttle)

Vue.prototype.$echarts = echarts

Vue.use(
  ElementUI
)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
